document.addEventListener('DOMContentLoaded', () => { // DOMContentLoaded contentReplaced
    'use strict'

    // NEWSLETTER ANIMSTION
    gsap.to("#parallax_hex span", 1, {
        scale: 0.97,
        y: 2,
        x: 1,
        yoyo: true,
        repeat: -1,
        ease: "power1.inOut",
        delay: 1,
        stagger: {
            each: 0.15,
            grid: "auto",
            from: "center"
        }
    });

    // DROPDOWN
    const dropSubmenu = document.querySelector('.navbar-nav')
    const showDrop = document.querySelector('.dropSubmenu')
    dropSubmenu.addEventListener('show.bs.dropdown', function () {
        dropSubmenu.classList.add('showSubmenu')
    });
    dropSubmenu.addEventListener('hide.bs.dropdown', function () {
        dropSubmenu.classList.remove('showSubmenu');
    });

    // ACTIVE LINK
    let currentPath = window.location.pathname;
    let links = document.querySelectorAll('.autoActive-link .aria-active a');
    for (const link of links) {
        let linkPath = (new URL(link.href)).pathname;
        link.ariaCurrent = linkPath == currentPath ? 'page' : false;
    };

    // FILEPOND - UPLOAD FILES
    FilePond.registerPlugin(
        FilePondPluginFileValidateType,
        FilePondPluginFileValidateSize,
        FilePondPluginImagePreview,
        FilePondPluginImageResize,
        FilePondPluginImageTransform
    );

    FilePond.setOptions({
        labelIdle: `اسحب المفات وأفلتها أو <span class="filepond--label-action">تصفح</span>`,
        labelInvalidField: 'الحقل يحتوي على ملفات غير صالحة',
        labelFileWaitingForSize: 'بانتظار الحجم',
        labelFileSizeNotAvailable: 'الحجم غير متاح',
        labelFileLoading: 'بالإنتظار',
        labelFileLoadError: 'حدث خطأ أثناء التحميل',
        labelFileProcessing: 'يتم الرفع',
        labelFileProcessingComplete: 'تم الرفع',
        labelFileProcessingAborted: 'تم إلغاء الرفع',
        labelFileProcessingError: 'حدث خطأ أثناء الرفع',
        labelFileProcessingRevertError: 'حدث خطأ أثناء التراجع',
        labelFileRemoveError: 'حدث خطأ أثناء الحذف',
        labelTapToCancel: 'انقر للإلغاء',
        labelTapToRetry: 'انقر لإعادة المحاولة',
        labelTapToUndo: 'انقر للتراجع',
        labelButtonRemoveItem: 'مسح',
        labelButtonAbortItemLoad: 'إلغاء',
        labelButtonRetryItemLoad: 'إعادة',
        labelButtonAbortItemProcessing: 'إلغاء',
        labelButtonUndoItemProcessing: 'تراجع',
        labelButtonRetryItemProcessing: 'إعادة',
        labelButtonProcessItem: 'رفع',
        labelMaxFileSizeExceeded: 'الملف كبير جدا',
        labelMaxFileSize: 'حجم الملف الأقصى: {filesize}',
        labelMaxTotalFileSizeExceeded: 'تم تجاوز الحد الأقصى للحجم الإجمالي',
        labelMaxTotalFileSize: 'الحد الأقصى لحجم الملف: {filesize}',
        labelFileTypeNotAllowed: 'ملف من نوع غير صالح',
        fileValidateTypeLabelExpectedTypes: 'تتوقع {allButLastType} من {lastType}',
        imageValidateSizeLabelFormatError: 'نوع الصورة غير مدعوم',
        imageValidateSizeLabelImageSizeTooSmall: 'الصورة صغير جدا',
        imageValidateSizeLabelImageSizeTooBig: 'الصورة كبيرة جدا',
        imageValidateSizeLabelExpectedMinSize: 'الحد الأدنى للأبعاد هو: {minWidth} × {minHeight}',
        imageValidateSizeLabelExpectedMaxSize: 'الحد الأقصى للأبعاد هو: {maxWidth} × {maxHeight}',
        imageValidateSizeLabelImageResolutionTooLow: 'الدقة ضعيفة جدا',
        imageValidateSizeLabelImageResolutionTooHigh: 'الدقة مرتفعة جدا',
        imageValidateSizeLabelExpectedMinResolution: 'أقل دقة: {minResolution}',
        imageValidateSizeLabelExpectedMaxResolution: 'أقصى دقة: {maxResolution}'
    });

    const FILEPOND_ITEMS = document.querySelectorAll('input[type="file"]');
    Array.from(FILEPOND_ITEMS).forEach(FILEPOND_ITEMS => {
        FilePond.create(FILEPOND_ITEMS);
    });


    // SHOW CONTENT - PREFLOAD
    let loader = document.querySelector(".loader-page");
    loader.className += " hidden";

    // ANIMATION GSAP
    gsap.config({
        nullTargetWarn: false,
        trialWarn: false,
    });

    let tl = gsap.timeline();

    tl.to(
        ".delay-1",
        {
            y: 0,
            duration: 1,
            ease: "power4.inOut"
        })
        .to(
            ".delay-2",
            {
                y: 0,
                duration: 1.25,
                ease: "power4.inOut"
            },
            0
        )
        .to(
            ".delay-3",
            {
                y: 0,
                duration: 1.5,
                ease: "power4.inOut"
            },
            0
        )
        .to(
            ".delay-4",
            {
                y: 0,
                duration: 1.75,
                ease: "power4.inOut"
            },
            0
        )
        .to(
            ".delay-5",
            {
                y: 0,
                duration: 2,
                ease: "power4.inOut"
            },
            0
        )

    // FILTERING
    let switcherList = document.querySelectorAll('.swithcer li');
    let splitShow = document.querySelectorAll('.splitShow .item');

    switcherList.forEach((li) => {
        li.addEventListener('click', removeActive);
        li.addEventListener('click', splits);
    })
    function removeActive() {
        switcherList.forEach((li) => {
            li.classList.remove("active");
            this.classList.add("active");
        });
    }
    function splits() {
        splitShow.forEach((item) => {
            item.classList.add("hide");
            item.classList.remove("show");
        });
        document.querySelectorAll(this.dataset.cat).forEach((el) => {
            el.classList.add("show");
            el.classList.remove("hide");
        });
    }

    // DROPDOWN SELECT 
    var Select = document.querySelectorAll(".drop_select");
    Select.forEach(function (select) {
        NiceSelect.bind(select);
    });

    // OS PREFERS COLOR SCHEME
    function detectColorScheme() {
        var theme = "dark";
        if (localStorage.getItem("theme")) {
            if (localStorage.getItem("theme") == "light") {
                var theme = "light";
            }
        } else if (!window.matchMedia) {
            return false;
        } else if (window.matchMedia("(prefers-color-scheme: light)").matches) {
            var theme = "light";
        }
        if (theme == "light") {
            document.documentElement.setAttribute("data-theme", "light");
        }

    }
    detectColorScheme();

    // DARK MODE ACTIVATION - SWITCH
    const toggleSwitch = document.querySelector('.theme-switch');

    function switchTheme(e) {
        if (e.target.checked) {
            localStorage.setItem('theme', 'light');
            document.documentElement.setAttribute('data-theme', 'light');
            toggleSwitch.checked = true;
        } else {
            localStorage.setItem('theme', 'dark');
            document.documentElement.setAttribute('data-theme', 'dark');
            toggleSwitch.checked = false;
        }
    }

    toggleSwitch.addEventListener('change', switchTheme, false);

    if (document.documentElement.getAttribute("data-theme") == "light") {
        toggleSwitch.checked = true;
    }


    // SHOW ADS
    var toastTrigger = document.querySelector('.btn_showAds')
    var toastLiveExample = document.querySelector('.toasts_ads')
    var btnClose = document.querySelector('.btnCloseAds')
    if (toastTrigger) {
        toastTrigger.addEventListener('click', function () {
            var toast = new bootstrap.Toast(toastLiveExample)
            toast.show()
            toastTrigger.classList.add("hide");
        })
        btnClose.addEventListener('click', function () {
            toastTrigger.classList.remove("hide");
        })
    }

    // SHOW MSG
    var toastTriggerMsg = document.querySelector('.inputToastResult');
    var toastLiveExampleMsg = document.querySelector('.liveToastMsg');


    if (toastTriggerMsg) {
        toastTriggerMsg.addEventListener('click', function () {
            var toast = new bootstrap.Toast(toastLiveExampleMsg)
            toast.show()
        })
    }
    
    // MEDIA QUEIRY
    const mediaQuery = window.matchMedia('(min-width: 768px)');
    function handleTabletChange(e) {
        if (e.matches) {
            // CUSTOM CURSOR
            MouseFollower.registerGSAP(gsap);
            const cursor = new MouseFollower({
                container: document.body,
                speed: 0.55,
                skewingIcon: 0,
                stateDetection: {
                    "-pointer": "button, label",
                    "-opaque": "img",
                    "-hidden": ".form-control, .hidden-cursor, .scrollbar-thumb, .form-check, .custom-select",
                }
            });
            // SMOTH SCROLLBAR

            const optionScroll = {
                renderByPixels: false,
                damping: 0.08,
            }
            let Scrollbar = window.Scrollbar;
            Scrollbar.init(document.querySelector('#wrapper'), optionScroll);

            // BACKGROUND ANIMATION
            var optionsgrained = {
                animate: true,
                patternWidth: 300,
                patternHeight: 300,
                grainOpacity: 0.04,
                grainDensity: 1,
                grainWidth: 1,
                grainHeight: 1,
            };
            grained("#grained", optionsgrained);
            // JSON SERVICE EMAIL JSON
            lottie.loadAnimation({
                container: document.getElementById('shape_newsletter'),
                renderer: 'svg',
                loop: true,
                autoplay: true,
                path: '../json/email.json'
            });
        }
    }
    mediaQuery.addListener(handleTabletChange)
    handleTabletChange(mediaQuery)



});
